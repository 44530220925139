
import { Page, Card, DataTable, TextContainer, TextStyle, Layout } from "@shopify/polaris"
import { useParams, useNavigate } from "react-router"

import { EVENT } from '../graphql/queries';
import { useQuery } from "@apollo/client";

const MatchDetails = () => {

  const navigate = useNavigate()
  const { id } = useParams()
  
  const { loading: isLoadingEvent, data: eventData } = useQuery(EVENT, { variables : { id } });

  if(isLoadingEvent) {
    return(
      <Page></Page>
    )
  }

  const { event } = eventData || { event : { } }

  return(
    <Page 
      title='Match'
      breadcrumbs={[{content: 'Matches', onAction: e => navigate(-1) }]}
    >
      <Layout>
        <Layout.Section>
          <Card title='Details'>
            <Card.Section title="Home team">
              {event.homeTeam.name}
            </Card.Section>
            <Card.Section title="Away team">
              {event.awayTeam.name}
            </Card.Section>
            <Card.Section title="Sport">
              {event.sport.name}
            </Card.Section>
            <Card.Section title="League">
              {event.league.name}
            </Card.Section>
            <Card.Section title="Starts at">
              {event.commenceDay}
            </Card.Section>
          </Card>
        </Layout.Section>
        {event.commenceEntries.map((commenceEntry, i) => {
          return(
            <Layout.Section key={i}>
              <Card>
                <Card.Header
                  title={`Odds collected for match starting at ${new Date(commenceEntry.commenceTime).toLocaleTimeString()}`}
                ></Card.Header>
                  {commenceEntry.odds.map((o, i) => {
                    return(
                      <>
                        <Card.Section title={o.bookmakerId}>
                          <TextContainer>
                            {o.values.map(v => `${v.name} = ${v.value}`).join(', ')}
                          </TextContainer>
                        </Card.Section>
                        <Card.Section title="History">
                          <Card sectioned>                            
                            {o.values.map((v, i) => {
                              if(v.history && v.history.length === 0) return(null);
                              return(
                                <section key={i}>
                                  <TextStyle variation="strong" >{v.name}</TextStyle>
                                  <TextContainer>
                                    {v.history.map(h => `${h.value}`).join(' => ')}
                                  </TextContainer>
                                </section>
                              )
                            })}
                          </Card>
                        </Card.Section>                        
                      </>
                    )
                  })}
              </Card>
              <Card>
                <Card.Header
                  title={`Surebets collected for match starting at ${new Date(commenceEntry.commenceTime).toLocaleTimeString()}`}
                ></Card.Header>
                <Card.Section>
                  <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'text',
                    ]}
                    headings={[
                      'Market',
                      'Profit',
                      'Bets'
                    ]}
                    rows={commenceEntry.surebets.map(sb => ([
                      sb.marketId,
                      sb.profit,
                      sb.bets.map(b => `${b.name}=${b.bookmakerId}=>${b.value}`).join(', ')
                    ]))}
                  >
                  </DataTable>
                </Card.Section>
              </Card>
            </Layout.Section>
          )
        })}
      </Layout>
    </Page>
  )
}

export { MatchDetails }