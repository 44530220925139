import { gql } from "@apollo/client";

export const GET_ME = gql`
query me {
  me {
    id
    username
    favorites {
        id
        type
        relationId
    }
  }
}
`

export const CREATE_USER = gql`
mutation createUser($username: String!) {
  createUser(username: $username) {
    id
    username
    favorites {
      id
      type
      relationId
    }
  }
}
`
