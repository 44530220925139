import React, { useState } from 'react';

import { Card, FormLayout, Page, TextField, Toast } from '@shopify/polaris';

import { api } from '../providers/api';

const BookmakerAdd = () => {

  const [bookmakerId, setBookmakerId] = useState();
  const [scriptName, setScriptName] = useState();
  const [isSaving, setIsSaving] = useState();
  const [saveError, setSaveError] = useState();
  const [successToastVisible, setSuccessToastVisible] = useState(false);

  const saveBookmaker = async () => {
    setIsSaving(true);
    try {
      await api.put('/bookmaker', {
        id : bookmakerId,
        script : scriptName
      });
      setSuccessToastVisible(true);
    } catch(e) {
      setSaveError(e.toString());
    }
    setIsSaving(false);
  }
  const successToast = successToastVisible ? (<Toast onDismiss={e => setSuccessToastVisible(false)} content='Saved' />) : null;

  return(
    <Page 
      title='Add bookmaker'
    >
      <Card
        title="Details"
        primaryFooterAction={{
          content : 'Add',
          onAction : saveBookmaker,
          loading: isSaving
        }}
      >
        <Card.Section>
          <FormLayout>
            <TextField label="Key" value={bookmakerId} onChange={setBookmakerId} placeholder='Enter bookmakerId'/>
            <TextField label="Script" value={scriptName} onChange={setScriptName} placeholder='Enter name of script' error={saveError} />
          </FormLayout>
        </Card.Section>
      </Card>
      {successToast}
    </Page>
  )
}

export { BookmakerAdd }