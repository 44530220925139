
import { Auth } from 'aws-amplify';
import axios from 'axios';

//const betfavorApiURI = `https://localhost:80`;
const betfavorApiURI = 'https://90ohrou8n9.execute-api.eu-north-1.amazonaws.com/prod';

const api = axios.create({
  baseURL: betfavorApiURI,
  timeout: 10000,
});

api.interceptors.request.use(async function(config) {
    let token = (await Auth.currentSession()).getIdToken().getJwtToken();
    config.headers.Authorization = token;
    return(config)
  }
);

api.interceptors.response.use((response) => {
  return(response.data)
})

export { api };