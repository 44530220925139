/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cognito_identity_pool_id": "eu-north-1:28bcff55-563a-49b1-adb9-d24e2146296f",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_1W2uaVdDT",
    "aws_user_pools_web_client_id": "2nkgrc8cfm4omo6j6hrl4q210i",
    "oauth": {
        "domain": "betfavoradminapp7759ab97-7759ab97-dev.auth.eu-north-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://admin.betfavor.com/,https://admin.betfavour.com/,http://localhost/,http://localhost:3000/,https://devel.betfavor.com/,https://www.betfavor.com/",
        "redirectSignOut": "https://admin.betfavor.com/,https://admin.betfavour.com/,http://localhost/,http://localhost:3000/,https://devel.betfavor.com/,https://www.betfavor.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
