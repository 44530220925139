
import {
    gql
} from "@apollo/client";

export const eventFragment = gql`
  fragment eventFragment on Event {
    id
    isLive
    sport { 
      id
      name
    }
    league {
      id
      name
      sport {
        name
      } 
    }
    homeTeam {
      name
    }
    awayTeam {
      name
    }
    updatedAt
  }
`;

export const paginatorFragment = gql`
  fragment paginatorProps on PaginatorInfo {
    count
    currentPage
    firstItem
    hasMorePages
    lastItem
    lastPage
    perPage
    total
  }
`;

export const EVENTS = gql`
    query events($live: Boolean, $favorites: Boolean, $market: String, $bookmakers: [String], $sports: [String], $leagues: [ID], $orderBy: String, $query: String, $page: Int!, $first: Int!) {
        events(live: $live, favorites: $favorites, market : $market, bookmakers: $bookmakers, sports: $sports, leagues: $leagues, page: $page, first: $first, orderBy: $orderBy, query: $query) {
        paginatorInfo {
            ...paginatorProps
        }
        data {
            ...eventFragment
            commenceEntries { 
            id
            commenceTime
            updatedAt
            best_h2h_odds {
                id
                name
                value
                oddsEntry {
                marketId
                bookmakerId
                bookmakerURI
                }
                history {
                id
                value
                }
            }
            }
        }
        }
    }
    ${eventFragment}
    ${paginatorFragment}
`

export const EVENT = gql`
    query event($id: ID!) {
        event(id: $id) {
            ...eventFragment
        }
    }
    ${eventFragment}
`

export const SPORTS = gql`
query sports($market: String, $first: Int!, $page: Int!) {
    sports(market: $market, first: $first, page: $page) {
        paginatorInfo {
            ...paginatorProps
        }
        data {
            id
            name
            aliases {
                id
                alias
            }
        }
    }
}
${paginatorFragment}
`;

export const TEAMS = gql`
query teams($query: String, $sports: [ID!], $page: Int!, $first: Int!) {
    teams(query: $query, sports: $sports, page: $page, first: $first) {
        paginatorInfo {
            ...paginatorProps
        }
        data {
            id
            name
            aliases {
                id
                alias
            }
            sport {
                id
                name
            }
        }
    }
}
${paginatorFragment}
`;

export const LEAGUES = gql`
  query leagues($query: String, $market: String, $sports: [String], $betType: String, $live: Boolean, $page: Int, $first: Int!) {
    leagues(query: $query, market: $market, sports: $sports, betType: $betType, live: $live, page: $page, first: $first) {
      paginatorInfo {
        total
        count
        currentPage
      }
      data {
        id
        sportId
        aliases {
            id
            alias
        }
        sport {
            id
            name
        }
        name
      }
    }
  }
`;

export const BOOKMAKERS = gql`
  query bookmakers($query: String, $page: Int, $first: Int!) {
    bookmakers(first: $first, page: $page, query: $query) {
      paginatorInfo {
        ...paginatorProps
      }
      data {
        id
        name
        bookmakerId
        websiteUrl
        script
        scriptStatus
        lastSchedule
        lastScheduleRuntime
      }
    }
  }
  ${paginatorFragment}
`;

export const BOOKMAKER = gql`
query bookmaker($id: ID!) {
  bookmaker(id: $id) {
    id
    name
    bookmakerId
    websiteUrl
    script
    scriptStatus
    lastSchedule
    lastScheduleRuntime
    lastErrorMessage
  }
}`;

export const NUM_SCHEDULED_SCRIPTS = gql`
query numScheduledScripts {
  numScheduledScripts {
    scheduled
    running
  }
}`

export const SCHEDULE_SCRIPT = gql`
mutation scheduleScript($id: ID!, $script: String!) {
  scheduleScript(id: $id, script: $script)
}`

export const UNSCHEDULE_SCRIPT = gql`
mutation unscheduleScript($id: ID!, $script: String!) {
  unscheduleScript(id: $id, script: $script)
}`

export const SCHEDULE_ALL_SCRIPTS = gql`
mutation scheduleAllScripts($limit: Int) {
  scheduleAllScripts(limit: $limit)
}`

export const UPDATE_BOOKMAKER = gql`
mutation updateBookmaker($id: ID!, $script: String, $websiteUrl: String) {
  updateBookmaker(id: $id, script: $script, websiteUrl: $websiteUrl)
}`

export const DELETE_BOOKMAKER = gql`
mutation scheduleScript($id: ID!, $script: String!) {
  scheduleScript(id: $id, script: $script)
}`

export const SET_TEAM_AS_ALIAS = gql`
mutation setTeamAsAlias($sourceTeamId: ID!, $targetTeamId: ID!) {
  setTeamAsAlias(sourceTeamId: $sourceTeamId, targetTeamId: $targetTeamId)
}`

export const REMOVE_TEAM_ALIAS = gql`
mutation removeTeamAlias($id: ID!) {
  removeTeamAlias(id: $id)
}`

export const SET_LEAGUE_AS_ALIAS = gql`
mutation setLeagueAsAlias($sourceLeagueId: ID!, $targetLeagueId: ID!) {
  setLeagueAsAlias(sourceLeagueId: $sourceLeagueId, targetLeagueId: $targetLeagueId)
}`

export const REMOVE_LEAGUE_ALIAS = gql`
mutation removeLeagueAlias($id: ID!) {
  removeLeagueAlias(id: $id)
}`

export const REMOVE_SPORT_ALIAS = gql`
mutation removeSportAlias($id: ID!) {
    removeSportAlias(id: $id)
}`