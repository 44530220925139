import React from 'react';

const defaultAuthState = {
  isAuthenticated: false,
  showLoginModal: false,
  showSignupModal: false,
  user: undefined
}
const AuthContext = React.createContext(defaultAuthState);

export { AuthContext, defaultAuthState }