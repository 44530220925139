import { useState, useEffect, useCallback } from 'react';
import { Filters, Page, Pagination, LegacyCard, DataTable, Link, Button } from '@shopify/polaris';

import { useSearchParams } from 'react-router-dom';

import { BOOKMAKERS, SCHEDULE_SCRIPT, UNSCHEDULE_SCRIPT, SCHEDULE_ALL_SCRIPTS, NUM_SCHEDULED_SCRIPTS } from '../graphql/queries';
import { useMutation, useQuery } from "@apollo/client";

import { useNavigate } from "react-router"

const BookmakerList = () => {

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  
  const getDefaultParams = () => {
    let params = {       
      page : parseInt(searchParams.get('page') || 1), 
      first : parseInt(searchParams.get('first') || 10)
    }
    if(searchParams.get('query'))
      params.query = searchParams.get('query');
    return(params);
  }

  const [bookmakersParams, setBookmakersParams ] = useState(getDefaultParams());
  let [refreshedAt, setRefreshedAt] = useState(new Date().toLocaleTimeString());

  const { data: bookmakersData, loading: isLoadingBookmakers, refetch : bookmakersRefetch } = useQuery(BOOKMAKERS, { variables : bookmakersParams });
  const { data: scheduledScriptsData, loading: isLoadingScripts, refetch : numScheduledScriptsRefetch } = useQuery(NUM_SCHEDULED_SCRIPTS);

  const [ scheduleScript ] = useMutation(SCHEDULE_SCRIPT);
  const [ unscheduleScript ] = useMutation(UNSCHEDULE_SCRIPT);
  const [ scheduleAll ] = useMutation(SCHEDULE_ALL_SCRIPTS);

  const [queryValue, setQueryValue] = useState(bookmakersParams.query);

  useEffect(() => {
    let t = setTimeout(() => {
      if(queryValue) {
        setBookmakersParams(params => ({ ...params, query : queryValue }))
      } else {
        setBookmakersParams(params => {
          let newParams = { ...params }
          delete newParams.query;
          return(newParams);
        })
      }
    }, 700);
    return () => {
      clearTimeout(t);
    }
  },[queryValue]);
  
  const handleFiltersQueryChange = useCallback((value) => setQueryValue(value), [], );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => { handleQueryValueRemove(); }, [ handleQueryValueRemove ]);

  const filters = [];

  const unscheduleBookmakerScriptRun = useCallback(async (r) => {
    const { id, script } = r;
    unscheduleScript({ variables: { id, script } })
    .then((done) => {
      numScheduledScriptsRefetch();
      bookmakersRefetch().then(() => {
        setRefreshedAt(new Date().toLocaleTimeString());
      })
    });
  }, [unscheduleScript, bookmakersRefetch, numScheduledScriptsRefetch]);

  const scheduleBookmakerScriptRun = useCallback(async (r) => {
    const { id, script } = r;
    scheduleScript({ variables: { id, script } })
    .then((done) => {
      numScheduledScriptsRefetch();
      bookmakersRefetch().then(() => {
        setRefreshedAt(new Date().toLocaleTimeString());
      })
    });
  }, [scheduleScript, bookmakersRefetch, numScheduledScriptsRefetch]);

  const scheduleAllScriptRun = useCallback(async (r) => {
    scheduleAll({
      variables: { limit : 50 }
    })
    .then((done) => {
      numScheduledScriptsRefetch();
      bookmakersRefetch().then(() => {
        setRefreshedAt(new Date().toLocaleTimeString());
      })
    });
  }, [scheduleAll, bookmakersRefetch, numScheduledScriptsRefetch]);
  
  useEffect(() => {
    setSearchParams(bookmakersParams);
  }, [bookmakersParams, setSearchParams]);

  useEffect(() => {
    const i = setInterval(() => {
      numScheduledScriptsRefetch();
      bookmakersRefetch().then(() => {
        setRefreshedAt(new Date().toLocaleTimeString());
      })
    }, 10000);
    return(() => {
      clearInterval(i);
    })
  }, [bookmakersRefetch, numScheduledScriptsRefetch])

  const paginatorInfo = bookmakersData?.bookmakers?.paginatorInfo;
  const bookmakers = bookmakersData?.bookmakers?.data;

  const sectionTitle = (isLoadingBookmakers || isLoadingScripts) ? 'loading...' : 
    `Refreshed ${refreshedAt}, ${scheduledScriptsData.numScheduledScripts.scheduled} scheduled, ${scheduledScriptsData.numScheduledScripts.running} running`;

  return(
    <Page
      title='Bookmakers'
      primaryAction={
        { 
          content : 'Add bookmaker',
          onAction : e => navigate('/bookmakers/add')
        }
      }
      secondaryActions={[
        {
          content : 'Run all',
          onAction : e => scheduleAllScriptRun(e)
        }
      ]}
    >
      <LegacyCard 
        title='All available bookmakers'        
      >
        <LegacyCard.Section>
          { sectionTitle }
        </LegacyCard.Section>
        <LegacyCard.Section>
          <Filters
            filters={filters}
            queryValue={queryValue}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </LegacyCard.Section>
        <LegacyCard.Section>
          <DataTable
            verticalAlign='middle'
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              'Id',
              'Script',
              'Last schedule',
              'Last run',
              'Action'
            ]}
            rows={bookmakers?.map(r => {
              const scheduleButton = (<Button onClick={e => scheduleBookmakerScriptRun(r)}>Schedule</Button>);
              const queuedButton = (<Button onClick={e => unscheduleBookmakerScriptRun(r)} primary >Queued</Button>);
              const cancelButton = (<Button onClick={e => unscheduleBookmakerScriptRun(r)} secondary>Abort run</Button>);
              const failedButton = (<Button destructive onClick={e => scheduleBookmakerScriptRun(r)}>Er, re-run</Button>);
              return([
                r.bookmakerId,
                (<Link
                    removeUnderline
                    onClick={e => navigate(`/bookmakers/${r.id}`)}
                  >{r.script}</Link>),
                  (r.lastSchedule && new Date(r.lastSchedule).toLocaleString()) || '-',
                  (r.lastScheduleRuntime && new Date(r.lastScheduleRuntime).toLocaleString()) || '-',
                r.scriptStatus === 'queued' ? queuedButton :
                r.scriptStatus === 'running' ? cancelButton :
                r.scriptStatus === 'failed' ? failedButton : scheduleButton
              ]);              
            }) ?? []}
          />
        </LegacyCard.Section>
        <LegacyCard.Section>
          {!isLoadingBookmakers && (
            <Pagination
              label={`Showing ${paginatorInfo.count * (paginatorInfo.currentPage - 1)} to ${Math.min(paginatorInfo.total, paginatorInfo.count * paginatorInfo.currentPage)} of ${paginatorInfo.total}`}
              hasPrevious={paginatorInfo.currentPage > 1}
              onPrevious={() => {
                setBookmakersParams(params => ({ ...params, page: paginatorInfo.currentPage - 1 }));
              }}
              hasNext={paginatorInfo.currentPage < paginatorInfo.lastPage}
              onNext={() => {
                setBookmakersParams(params => ({ ...params, page: paginatorInfo.currentPage + 1 }));
              }}
            />
          )}
        </LegacyCard.Section>
      </LegacyCard>
    </Page>
  )
}

export { BookmakerList }