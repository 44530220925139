import { useEffect, useState, useCallback} from "react";

import { Page, Card, DataTable } from "@shopify/polaris"
import { useParams, useNavigate } from "react-router"

import { api } from '../providers/api';

const MatchHistory = () => {

  const navigate = useNavigate()
  const { id, bookmakerId, marketId } = useParams()
  const [data, setData] = useState({ odds : [], league : { } });
  
  const load = useCallback(async (id) => {
    if(id) {
      let data = await api.get(`/match/${id}`).then(r => r._source);
      setData(data);
    }
  }, [])

  useEffect(() => {
    load(id);
  }, [id, load]);

  let matched = data.odds.find(o => o.bookmakerId === bookmakerId && o.marketId === marketId);
  const history = [...(matched || { history : [] }).history];

  if(matched) {
    history.unshift({ values : matched.values, date : matched.values[0].timestamp })
  }
  return(

    <Page 
      title='Match'
      breadcrumbs={[
        {content: 'Matches', onAction: e => navigate(`/matches/`) },
        {content: 'Details', onAction: e => navigate(`/matches/${id}`) },
      ]}
    >
      <Card title='Odds collected'>
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Bookmaker',
            'Market',
            'Updated',
            'Values',
          ]}
          rows={history.map(o => ([
            bookmakerId,
            marketId,
            (o.date && new Date(o.values[0].timestamp || o.date).toLocaleString()) || '-',
            o.values.map(v => `${v.name}=${v.value}`).join(', ')
          ]))}
        >
        </DataTable>
      </Card>
    </Page>
  )
}

export { MatchHistory }