import { useState, useRef, useCallback, useReducer, useEffect, useContext } from 'react';

import { AppProvider, ActionList, TopBar, Navigation, Frame, Modal, VerticalStack, TextField, FormLayout, Spinner } from '@shopify/polaris';
import { ArrowLeftMinor, HomeMajor, OrdersMajor, ExitMajor } from '@shopify/polaris-icons';
import { AuthContext, defaultAuthState } from './AuthContext';

import './App.css';
import '@shopify/polaris/build/esm/styles.css';

import enTranslations from '@shopify/polaris/locales/en.json';
import { HashRouter as Router, Route, Routes, useNavigate, useMatch } from "react-router-dom"

import { MatchList } from './pages/MatchList';
import { DashBoard } from './pages/DashBoard';
import { MatchDetails } from './pages/MatchDetails';
import { MatchHistory } from './pages/MatchHistory';
import { BookmakerList } from './pages/BookmakerList';
import { TeamsList } from './pages/TeamsList';
import { LeaguesList } from './pages/LeaguesList';
import { BookmakerAdd } from './pages/BookmakerAdd';
import { BookmakerEdit } from './pages/BookmakerEdit';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { CREATE_USER, GET_ME } from './graphql/auth';

Amplify.configure(awsconfig);

const authReducer = (state, action) => {
  switch(action.type) {
    case "showLoginModal":
      return({ ...state, showLoginModal : true, showSignupModal : false, });
    case "hideLoginModal":
      return({ ...state, showLoginModal : false });
    case "showSignupModal":
      return({ ...state, showSignupModal : true, showLoginModal : false });
    case "hideSignupModal":
      return({ ...state, showSignupModal : false });
    case "setIsAuthenticated":
      if(action.value === true)
        return({ ...state, isAuthenticated : action.value, showSignupModal : false, showLoginModal : false });
      else
        return({ ...state, isAuthenticated : action.value, });
    case "user":
      return({ ...state, user : action.value, });
      default:
        return(state)
  }
}

function AppFrame(props) {

  const navigate = useNavigate();
  
  const { state : authState, dispatch : dispatchAuthState } = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [loginError, setLoginError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const skipToContentRef = useRef(null);
  const [userMenuActive, setUserMenuActive] = useState(false);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);

  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const signOut = useCallback(() => {
    Auth.signOut();
    dispatchAuthState({ type : 'setIsAuthenticated', value : false })
  }, [dispatchAuthState])

  const searchResultsMarkup = (
    <ActionList
      items={[{content: 'Shopify help center'}, {content: 'Community forums'}]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const userMenuMarkup = authState.isAuthenticated ? (
    <TopBar.UserMenu
      actions={[{
        items: [{content: 'Sign out', icon: ExitMajor, onAction : signOut }],
      }]}
      name={authState.user.firstName || authState.user.email}
      detail={''}
      initials="MS"
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  ) : undefined;

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchResultsVisible={searchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const menuItems = [
    {
      label: 'Dashboard',
      icon: HomeMajor,
      selected : useMatch('/'),
      onClick: () => { navigate('/') },
    },
    {
      label: 'Matches',
      icon: OrdersMajor,
      selected : useMatch('/matches'),
      onClick: () => { navigate('/matches') },
    },
    {
      label: 'Bookmakers',
      icon: OrdersMajor,
      selected : useMatch('/bookmakers'),
      onClick: () => { navigate('/bookmakers') },
    },
    {
      label: 'Teams',
      icon: OrdersMajor,
      selected : useMatch('/teams'),
      onClick: () => { navigate('/teams') },
    },
    {
      label: 'Leagues',
      icon: OrdersMajor,
      selected : useMatch('/leagues'),
      onClick: () => { navigate('/leagues') },
    },
  ]

  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            label: 'Back to Site',
            icon: ArrowLeftMinor,
            onClick: () => { window.location.href = 'https://devel.betfavor.com' },
          },
        ]}
      />
      <Navigation.Section
        separator
        title="Betfavor admin"
        items={menuItems}
      />
    </Navigation>
  );

  const performLogin = async () => {
    setLoginError(undefined);
    try {
      setIsSigningIn(true);
      let currentUser = await Auth.signIn({ username, password });
      if(currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsSigningIn(false);
        setChangePasswordModalVisible(true);
      } else {
        let token = (await Auth.currentSession()).getIdToken().getJwtToken();
        sessionStorage.setItem('token', token);
        document.location.reload();
      }
    } catch(e) {
      setIsSigningIn(false);
      setLoginError(e.message);
      console.log('Login error', e.message);
    }
  }

  const performPasswordChange = async () => {
    setPasswordError(undefined);
    try {
      setIsChangingPassword(true);
      setLoginError(undefined);
      document.location.reload();
    } catch(e) {
      setIsChangingPassword(false);
      setPasswordError(e.message);
      console.log('Login error', e.message);
    }
  }

  if(changePasswordModalVisible) {
    return(
      <Modal
        open={true}
        title="You need to change your password"
        primaryAction={{
          content: 'Sign in',
          onAction : performPasswordChange,
          loading : isChangingPassword && newPassword === newPasswordAgain
        }}
      >
        <Modal.Section>
          <VerticalStack>
            <FormLayout>
            <TextField label="New Password" type="password" value={newPassword} onChange={setNewPassword} requiredIndicator placeholder='Enter password' />
            <TextField label="New Password Again" type="password" value={newPasswordAgain} onChange={setNewPasswordAgain} requiredIndicator placeholder='Enter password' error={passwordError} />
            </FormLayout>
          </VerticalStack>
        </Modal.Section>
      </Modal>
    )
  }

  if(!authState.isAuthenticated) {
    return(
      <Modal
        open={true}
        title="Sign in to access admin"
        primaryAction={{
          content: 'Sign in',
          onAction : performLogin,
          loading : isSigningIn
        }}
      >
        <Modal.Section>
          <VerticalStack>
            <FormLayout>
              <TextField label="Username / Email" value={username} onChange={setUsername} requiredIndicator placeholder='Enter usermail or email' />
              <TextField label="Password" type="password" value={password} onChange={setPassword} requiredIndicator placeholder='Enter password' error={loginError} />
            </FormLayout>
          </VerticalStack>
        </Modal.Section>
      </Modal>
    )
  }

  return (
    <Frame
      topBar={topBarMarkup}
      navigation={navigationMarkup}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef.current}
    >
      <Routes>
        <Route path="/" element={<DashBoard />} />
        <Route path="/bookmakers" element={<BookmakerList />} />
        <Route path="/bookmakers/add" element={<BookmakerAdd />} />
        <Route path="/bookmakers/:bookmakerId" element={<BookmakerEdit />} />
        <Route path="/matches" element={<MatchList />} />
        <Route path="/matches/:id" element={<MatchDetails />} />
        <Route path="/matches/odds-history/:id/:bookmakerId/:marketId" element={<MatchHistory />} />
        <Route path="/teams" element={<TeamsList />} />
        <Route path="/leagues" element={<LeaguesList />} />
      </Routes>
    </Frame>
  );
}

const App = ({ client }) => {

  const [authState, dispatchAuthState] = useReducer(authReducer, defaultAuthState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let currentUser = await Auth.currentAuthenticatedUser();        
        let user = await client.query({ 
          query: GET_ME
        }).then(r => r.data.me);
        if(!user) {
          await client.mutate({
            mutation: CREATE_USER,
            variables: { username: currentUser.username }
          });
        }
        //
        let {attributes} = currentUser;
        dispatchAuthState({ type : 'user', value : { firstName : attributes.givenName, lastName : attributes.familyName, email : attributes.email } })
        dispatchAuthState({ type : 'setIsAuthenticated', value : true });
        setIsLoading(false);
      } catch(e) {
        setIsLoading(false);
      }
    })();
  }, [client]);

  if(isLoading) {
    return(
      <div style={{ position: 'fixed', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%'}}>
        <Spinner  />
      </div>
    );
  }

  return(
    <Router>
      <AuthContext.Provider value={{ state: authState, dispatch : dispatchAuthState}}>
        <AppProvider i18n={enTranslations}>
          {!isLoading && (
            <AppFrame client={client} />
          )}
        </AppProvider>
      </AuthContext.Provider>
    </Router>
  )
}

export default App;
