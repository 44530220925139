import { useState, useEffect, useCallback } from 'react';
import { Filters, Page, Pagination, LegacyCard, DataTable, Link, TextField, Spinner } from '@shopify/polaris';

import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { EVENTS } from '../graphql/queries';
import { useQuery } from "@apollo/client";

function disambiguateLabel(key, value) {
  switch (key) {
    case 'moneySpent':
      return `Money spent is between $${value[0]} and $${value[1]}`;
    case 'taggedWith':
      return `Tagged with ${value}`;
    case 'accountStatus':
      return value.map((val) => `Customer ${val}`).join(', ');
    default:
      return value;
  }
}

function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === '' || value == null;
  }
}

const MatchList = () => {

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const getDefaultParams = () => {
    let params = { 
      page: parseInt(searchParams.get('page') || 1), 
      first: parseInt(searchParams.get('first') || 20)
    }
    if(searchParams.get('sports'))
      params.sports = [searchParams.get('sports')];
    if(searchParams.get('bookmakers'))
      params.bookmakers = [searchParams.get('bookmakers')];
    if(searchParams.get('query'))
      params.query = searchParams.get('query');
    return(params);
  }
  
  const [eventsParams, setEventsParams ] = useState(getDefaultParams());
  const { loading: isLoadingEvents, data: eventsData } = useQuery(EVENTS, { variables: eventsParams });
  
  const [bookmakerId, setBookmakerId] = useState(eventsParams.bookmakers ? eventsParams.bookmakers[0] : undefined);
  const [sportId, setSportId] = useState(eventsParams.sports ? eventsParams.sports[0]: undefined);
  const [queryValue, setQueryValue] = useState(eventsParams.query);
  
  const handleFiltersQueryChange = useCallback((value) => setQueryValue(value), [], );
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleBookmakerIdChanged = useCallback((value) => setBookmakerId(value), []);
  const handleSportIdChanged = useCallback((value) => setSportId(value), []);

  const handleBookmakerIdRemove = useCallback(() => setBookmakerId(null), []);
  const handleSportIdRemove = useCallback(() => setSportId(null), []);  
  const handleFiltersClearAll = useCallback(() => { handleQueryValueRemove(); handleBookmakerIdRemove(); handleSportIdRemove(); }, [ handleQueryValueRemove, handleBookmakerIdRemove, handleSportIdRemove ]);

  useEffect(() => {
    let t = setTimeout(() => {
      setEventsParams(params => {
        let newParams = { page : params.page, first : params.first }
        if(sportId)
          newParams.sports = [sportId];
        if(bookmakerId)
          newParams.bookmakers = [bookmakerId];
        if(queryValue)
          newParams.query = queryValue;
        return(newParams);
      });
    }, 700);
    return () => {
      clearTimeout(t);
    }
  }, [queryValue, sportId, bookmakerId]);

  const filters = [
    {
      key: 'bookmakerId',
      label: 'Bookmaker',
      filter: (
        <TextField
          label="Bookmaker"
          value={bookmakerId}
          onChange={handleBookmakerIdChanged}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: 'sportId',
      label: 'Sport',
      filter: (
        <TextField
          label="Sport"
          value={sportId}
          onChange={handleSportIdChanged}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    }
  ];

  useEffect(() => {
    setSearchParams(eventsParams);
  }, [eventsParams, setSearchParams]);

  const appliedFilters = [];

  if (!isEmpty(bookmakerId)) {
    const key = 'bookmakerId';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, bookmakerId),
      onRemove: handleBookmakerIdRemove,
    });
  }

  if (!isEmpty(sportId)) {
    const key = 'sportId';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sportId),
      onRemove: handleSportIdRemove,
    });
  }
  

  const paginatorInfo = eventsData?.events?.paginatorInfo;
  const events = eventsData?.events?.data;

  return(
    <Page
      title='Matches'
    >
      <LegacyCard title='All matches in database'>
        <LegacyCard.Section>
          <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            queryValue={queryValue}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </LegacyCard.Section>
        <LegacyCard.Section>
          <DataTable
            footerContent={isLoadingEvents && (
              <Spinner />
            )}
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              'Id',
              'Sport',
              'League',
              'Home',
              'Away',
              'Updated',
              'Commence'
            ]}
            rows={events?.map(event => {
              return([
                (
                  <Link
                    removeUnderline
                    onClick={e => navigate(`/matches/${event.id}`)}
                  >{event.id}</Link>
                ),
                event.sport.name,
                event.league.name,
                event.homeTeam.name,
                event.awayTeam.name,
                event.updatedAt,
                event.commenceDay
              ])
            }) ?? []}
          />
        </LegacyCard.Section>
        <LegacyCard.Section>
          {!isLoadingEvents && (
            <Pagination
              label={`Showing ${paginatorInfo.count * (paginatorInfo.currentPage - 1)} to ${Math.min(paginatorInfo.total, paginatorInfo.count * paginatorInfo.currentPage)} of ${paginatorInfo.total}`}
              hasPrevious={paginatorInfo.currentPage > 1}
              onPrevious={() => {
                setEventsParams(eventsParams => ({ ...eventsParams, page: paginatorInfo.currentPage - 1 }));
              }}
              hasNext={paginatorInfo.currentPage < paginatorInfo.lastPage}
              onNext={() => {
                setEventsParams(eventsParams => ({ ...eventsParams, page: paginatorInfo.currentPage + 1 }));
              }}
            />
          )}
        </LegacyCard.Section>
      </LegacyCard>
    </Page>
  )
}

export { MatchList }