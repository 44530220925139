import React, { useState, useEffect } from 'react';

import { Toast, LegacyCard, FormLayout, Page, SkeletonBodyText, TextField } from '@shopify/polaris';

import { BOOKMAKER, UPDATE_BOOKMAKER, DELETE_BOOKMAKER } from '../graphql/queries';
import { useMutation, useQuery } from "@apollo/client";

import { useParams } from 'react-router';

const BookmakerEdit = () => {

  const { bookmakerId } = useParams();
  
  const [scriptName, setScriptName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(undefined);
  const [successToastVisible, setSuccessToastVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState();

  const { data: bookmakerData, loading: isLoading } = useQuery(BOOKMAKER, { variables : { id : bookmakerId } });
  const [ updateBookmaker ] = useMutation(UPDATE_BOOKMAKER);
  const [ deleteBookmaker ] = useMutation(DELETE_BOOKMAKER);

  const doDeleteBookmaker = async () => {
    setIsDeleting(true);
    try {
      await deleteBookmaker({ variables : { id : bookmakerId } });
      setSaveError(undefined);
    } catch (e) {
      setSaveError(e.message);
    }
    setIsDeleting(false);
  }

  useEffect(() => {
    if(bookmakerData) {
      setScriptName(bookmakerData.bookmaker.script || '')
      setWebsiteUrl(bookmakerData.bookmaker.websiteUrl || '')
    }
  }, [bookmakerData]);

  const successToast = successToastVisible ? (<Toast onDismiss={e => setSuccessToastVisible(false)} content="Saved" />) : null;

  const doUpdateBookmaker = async () => {
    setIsSaving(true);
    try {
      await updateBookmaker({ variables : { id : bookmakerId, script : scriptName, websiteUrl : websiteUrl } });
      setSaveError(undefined);
    } catch (e) {
      setSaveError(e.message);
    }
    setIsSaving(false);
  }

  return(
    <Page 
      title='Edit bookmaker'
      backAction={
        { 'content' : 'Bookmakers', url: '#/bookmakers' }
      }
      secondaryActions={[{
        'content': 'Delete',
        onAction: doDeleteBookmaker,
        loading: isDeleting,
        destructive: true
      }]}
    >
      <LegacyCard
        title="Details"
        primaryFooterAction={{
          content : 'Save',
          onAction : doUpdateBookmaker,
          loading: isSaving
        }}
      >
        {isLoading && (
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
        )}
        {!isLoading && (
          <LegacyCard.Section>
            <FormLayout>
              <TextField label="Script" value={scriptName} onChange={setScriptName} placeholder='Enter name of script' error={saveError} />            
              <TextField label="Website" value={websiteUrl} onChange={setWebsiteUrl} placeholder='Enter URI to site' error={saveError} />
            </FormLayout>
          </LegacyCard.Section>
        )}
      </LegacyCard>
      <LegacyCard title="Status">
        {isLoading && (
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
        )}
        {!isLoading && (
          <LegacyCard.Section>
            <div>
              {bookmakerData?.bookmaker?.scriptStatus}
            </div>
            {bookmakerData?.bookmaker?.scriptStatus === 'failed' && (
              <div>
                {bookmakerData?.bookmaker?.lastErrorMessage}
              </div>
            )}
          </LegacyCard.Section>
        )}    
      </LegacyCard>
      {successToast}
    </Page>
  )
}

export { BookmakerEdit }