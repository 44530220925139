import { useState, useEffect, useCallback } from 'react';
import { Filters, Page, Pagination, LegacyCard, Modal, VerticalStack, FormLayout, TextField, Autocomplete, Icon, ResourceList, ResourceItem, Spinner } from '@shopify/polaris';
import { SearchMinor, DeleteMinor } from "@shopify/polaris-icons";

import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '../util';

import { TEAMS, SPORTS, SET_TEAM_AS_ALIAS, REMOVE_TEAM_ALIAS } from '../graphql/queries';
import { useQuery, useMutation } from "@apollo/client";

function disambiguateLabel(key, value) {
  switch (key) {
    case 'moneySpent':
      return `Money spent is between $${value[0]} and $${value[1]}`;
    case 'taggedWith':
      return `Tagged with ${value}`;
    case 'accountStatus':
      return value.map((val) => `Customer ${val}`).join(', ');
    default:
      return value;
  }
}

const TeamsList = () => {

  let [searchParams, setSearchParams] = useSearchParams();
  
  const getDefaultParams = () => {
    let params = {       
      first: parseInt(searchParams.get('first') || 100), 
      page: parseInt(searchParams.get('page') || 1),
      sports: []
    }
    if(searchParams.get('sports'))
      params.sports = typeof(searchParams.get('sports')) === "string" ? [searchParams.get('sports')] : searchParams.get('sports');
    if(searchParams.get('query'))
      params.query = searchParams.get('query');
    return(params);
  }

  const [teamsParams, setTeamsParams ] = useState(getDefaultParams());

  const { data: teamsData, loading: isLoadingTeams, refetch: refetchTeams } = useQuery(TEAMS, { variables : teamsParams });
  const { data: sportsData } = useQuery(SPORTS, { variables: { page: 1, first: 100 } });
  const [ setAsTeamAsAlias, { loading: setTeamAsAliasLoading } ] = useMutation(SET_TEAM_AS_ALIAS);
  const [ removeTeamAlias, { loading: removeTeamAliasLoading } ] = useMutation(REMOVE_TEAM_ALIAS);

  const [sports, setSports] = useState([]);
  const [visibleSports, setVisibleSports] = useState([]);

  const [queryValue, setQueryValue] = useState(teamsParams.query || '');  
  const [sportFilterValue, setSportFilterValue] = useState("");
  const debouncedQueryValue = useDebounce(queryValue, 750);

  const handleSportIdRemove = useCallback((sportId) => {
    setTeamsParams(teamsParams => ({ ...teamsParams, sports: teamsParams.sports.filter(s => s !== sportId) }));
  }, []);

  useEffect(() => {
    if(sportsData) {
      let newSports = sportsData.sports.data.map(s => ({ value: s.name, label: s.name }));
      setSports(newSports);
      setVisibleSports(newSports);
    }
  }, [sportsData]);

  const updateSportFilter = useCallback((value) => {
    setSportFilterValue(value);
    if (value === "")
      return setVisibleSports(sports);
    const filterRegex = new RegExp(value, "i");
    const resultOptions = sports.filter((option) =>
      option.label.match(filterRegex)
    );
    setVisibleSports(resultOptions);
  },[sports]);
  
  const updateSelectedSports = (selected) => {
    setTeamsParams(params => ({ ...params, sports: selected }));
    setSportFilterValue();
  }
  
  useEffect(() => {
    setTeamsParams(params => ({ ...params, query: debouncedQueryValue }));
  }, [debouncedQueryValue]);

  const handleFiltersQueryChange = useCallback((value) => setQueryValue(value), []);  
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleFiltersClearAll = useCallback(() => handleQueryValueRemove(), [ handleQueryValueRemove ]);

  const sportFilterField = (
    <Autocomplete.TextField
      onChange={updateSportFilter}
      label="Sports"
      value={sportFilterValue}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Search"
    />
  );

  const filters = [
    {
      key: 'sportId',
      label: 'Sport',
      filter: (
        <Autocomplete
          allowMultiple
          options={visibleSports}
          selected={teamsParams.sports}
          onSelect={updateSelectedSports}
          textField={sportFilterField}
        />        
      ),
      shortcut: true,
    }
  ];

  const appliedFilters = [];
  for(const sportId of teamsParams.sports) {
    appliedFilters.push({
      key: sportId,
      label: disambiguateLabel(sportId, sportId),
      onRemove: handleSportIdRemove,
    });
  }
  
  useEffect(() => {
    setSearchParams(teamsParams);
  }, [teamsParams, setSearchParams]);

  const [useAsAliasDialogVisible, setUseAsAliasDialogVisible] = useState(false);
  const [aliasTargetTeamId, setAliasTargetTeamId] = useState(0);
  const [aliasSourceTeamId, setAliasSourceTeamId] = useState(0);

  const setAsAlias = (souceTeam) => { 
    setUseAsAliasDialogVisible(true); 
    setAliasSourceTeamId(souceTeam.id);
  }

  const setAsAliasAction = async () => {
    await setAsTeamAsAlias({ variables : {
      sourceTeamId: aliasSourceTeamId,
      targetTeamId: aliasTargetTeamId
    }});
    setUseAsAliasDialogVisible(false);
    refetchTeams();
    setAliasTargetTeamId(0)
  }

  const [removeAliasModalVisible, setRemoveAliasModalVisible] = useState(false);  
  const [removeTeamAliasId, setRemoveTeamAliasId] = useState(0);

  const removeAlias = (alias) => { 
    setRemoveAliasModalVisible(true); 
    setRemoveTeamAliasId(alias.id);
  }

  const removeTeamAliasAction = async () => {
    await removeTeamAlias({ variables : { id: removeTeamAliasId }});
    setRemoveAliasModalVisible(false); 
    refetchTeams();
  }

  const paginatorInfo = teamsData?.teams?.paginatorInfo;
  const teams = teamsData?.teams?.data;

  const renderTeam = (team) => {
    return(
      <ResourceItem
        id={team.id}
        shortcutActions={[{ content: 'Use as alias', onAction: e => { setAsAlias(team) } }]}
      >
        <div className="flex flex-row gap-3">
          <div className="flex justify-center items-center w-10">
            <div className="rounded bg-slate-100 p-1">{team.id}</div>
          </div>
          <div className="flex flex-col w-11/12 justify-center items-start">
            <div className="flex flex-row gap-2">
              <span className="font-bold">{team.name||'missing name'}</span>
              <span className="rounded bg-slate-100 p-1 text-xs">{team.sport.name}</span>
            </div>
            {(team.aliases.length === 0) && (
              <span>No aliases yet</span>
            )}
            {team.aliases.length > 0 && (
              <div className="flex flex-row gap-2 mt-3 flex-wrap">
                {team.aliases.map(alias => {
                  return(
                    <div key={alias.id} className="rounded text-xs p-2 bg-slate-50 flex justify-between items-center">
                      <span className="mr-1">{alias.alias}</span>
                      <button onClick={e => removeAlias(alias)}><Icon source={DeleteMinor} color="base" /></button>
                    </div>
                  )
                })}
              </div>
            )}
          </div>          
        </div>
      </ResourceItem>
    );
  }

  const paginationMarkup = (
    <div className="py-2 px-0">
      {!isLoadingTeams && (
        <Pagination
          label={`Showing ${paginatorInfo.count * (paginatorInfo.currentPage - 1)} to ${Math.min(paginatorInfo.total, paginatorInfo.count * paginatorInfo.currentPage)} of ${paginatorInfo.total}`}
          hasPrevious={paginatorInfo.currentPage > 1}
          onPrevious={() => {
            setTeamsParams(params => ({ ...params, page: paginatorInfo.currentPage - 1 }));
          }}
          hasNext={paginatorInfo.currentPage < paginatorInfo.lastPage}
          onNext={() => {
            setTeamsParams(params => ({ ...params, page: paginatorInfo.currentPage + 1 }));
          }}
        />
      )}
    </div>
  )

  return(
    <Page
      title='Teams'
    >
      <LegacyCard 
        title='All available teams'        
      >
        <LegacyCard.Section>
          <Filters
            filters={filters}
            appliedFilters={appliedFilters}
            queryValue={queryValue}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </LegacyCard.Section>
        <LegacyCard.Section>
          {paginationMarkup}
        </LegacyCard.Section>
        <LegacyCard.Section>
          {isLoadingTeams && (
            <div className='flex justify-center items-center p-12'>
              <Spinner />
            </div>
          )}
          {!isLoadingTeams && (
            <ResourceList
              items={teams}
              renderItem={renderTeam}
            />
          )}
        </LegacyCard.Section>
        <LegacyCard.Section>
          {paginationMarkup}
        </LegacyCard.Section>
      </LegacyCard>
      {/* alias modal */}
      <Modal
        open={useAsAliasDialogVisible}
        onClose={e => setUseAsAliasDialogVisible(false)}
        title="Set alias"
        primaryAction={{
          content: 'Set',
          onAction: setAsAliasAction,
          loading: setTeamAsAliasLoading
        }}
        secondaryActions={[{
          'content' : 'Cancel',
          onAction : e => setUseAsAliasDialogVisible(false)
        }]}
      >
        <Modal.Section>
          <VerticalStack>
            <FormLayout>
              <TextField label="Target team id" type="number" value={aliasTargetTeamId} onChange={setAliasTargetTeamId} requiredIndicator placeholder='ID of target team' />
            </FormLayout>
          </VerticalStack>
        </Modal.Section>
      </Modal>
      <Modal
        open={removeAliasModalVisible}
        onClose={e => setRemoveAliasModalVisible(false)}
        title="Remove alias"
        primaryAction={{
          content: 'Remove',
          destructive: true,
          onAction: removeTeamAliasAction,
          loading: removeTeamAliasLoading
        }}
        secondaryActions={[{
          'content' : 'Cancel',
          onAction : e => setRemoveAliasModalVisible(false)
        }]}
      >
        <Modal.Section>
          <VerticalStack>
            <FormLayout>
              Removing a alias can not be undone, do you want to continue?
            </FormLayout>
          </VerticalStack>
        </Modal.Section>
      </Modal>
    </Page>
  )
}

export { TeamsList }